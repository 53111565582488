
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";

import { Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";

function App(props) {
  async function handleLogout() {
  await Auth.signOut();

  userHasAuthenticated(false);
  props.history.push("/login");
}
  const [isAuthenticated, userHasAuthenticated] = useState(false);
const [isAuthenticating, setIsAuthenticating] = useState(true);
const deadline = '2019-12-01'

useEffect(() => {
  onLoad();
}, []);

async function onLoad() {
  try {
    await Auth.currentSession();
    userHasAuthenticated(true);
  }
  catch(e) {
    if (e !== 'No current user') {
      alert(e);
    }
  }

  setIsAuthenticating(false);
}



return (
!isAuthenticating &&
<div className="App">
  <Routes appProps={{ isAuthenticated, userHasAuthenticated, deadline }} />
</div>
  );
}

export default withRouter(App);
