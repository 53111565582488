import React, { useState, useEffect } from "react";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";

import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import Footer from './Footer.js';
import { Alert } from 'reactstrap';

export default function Signup(props) {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: ""
  });
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
const [alertMessage, setAlert] = useState("");
const [alertConfirmationMessage, setConfirmationAlert] = useState("");

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
  event.preventDefault();

  setIsLoading(true);

  try {
    const newUser = await Auth.signUp({
      username: fields.email,
      password: fields.password
    });
    setIsLoading(false);
    setNewUser(newUser);
    props.history.push("/waitinglist");
  } catch (e) {
    if (e.code == 'UsernameExistsException'){
    props.history.push("/waitinglist");
      setIsLoading(false);


    }
    else {
      setAlert(e.message);
    setIsLoading(false);
  }
  }
}

async function handleConfirmationSubmit(event) {
  event.preventDefault();

  setIsLoading(true);

  try {
    await Auth.confirmSignUp(fields.email, fields.confirmationCode);
    await Auth.signIn(fields.email, fields.password);

    props.userHasAuthenticated(true);
    props.history.push("/dashboard");
  } catch (e) {
    setConfirmationAlert(e.message);
    setIsLoading(false);
  }
}

  function renderConfirmationForm() {
    return (
<div className="general-background">
<p>
<img class='logo' src="/images/logo.png"/>
</p>
      <form onSubmit={handleConfirmationSubmit}>


      <p>
          <input className='inputBoxes'
          value={fields.confirmationCode}
          onChange={handleFieldChange}
          id="confirmationCode"
          type="name"
            placeholder="Confirmation Code"
          />
          </p>

          <HelpBlock className="Collapsible">Please check your email for the code.</HelpBlock>

          { alertConfirmationMessage ? (
            <Alert color="dark">
            {alertConfirmationMessage}
          </Alert>
        ) : null}

          <button
              type="submit"
              className="modalbutton"
              disabled={!validateConfirmationForm()}
            >
            Request Access
            </button>
      </form>
      </div>
    );
  }




  function renderForm() {
    return (

<section>
      <div className="general-background">
      <p>
      <img class='logo' src="/images/logo.png"/>
      </p>
    <p>
    </p>
    <p>
    </p>


    <form onSubmit={handleSubmit}>


  <p>
        <input className='inputBoxes'
        value={fields.email}
        onChange={handleFieldChange}
        id="email"
        type="email"
          placeholder="Email"
        />
        </p>

  <p>
        <input className='inputBoxes'
        value={fields.password}
        onChange={handleFieldChange}
        id="password"
        type="password"
          placeholder="Password"
        />
  </p>
  <p>
        <input className='inputBoxes'
        onChange={handleFieldChange}
        value={fields.confirmPassword}
        id="confirmPassword"
        type="password"
          placeholder="Retype Password"
        />
  </p>

  {alertMessage ? (
    <Alert color="dark">
    {alertMessage}
  </Alert>
  ) : null}

        <button
            type="submit"
            className="modalbutton"
            disabled={!validateForm()}
          >
          Request Access
          </button>
          <Link to="/playmenu" className="modalbutton" >Back</Link>





    </form>


    </div>
<Footer/>

          </section>



    );
  }

  return (
    <div>
      {renderForm()}
    </div>
  );
}
