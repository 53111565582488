import React, { useRef, useState, useEffect } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";

import { API } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";

export default function MissionUpload(props) {
  const file = useRef(null);
  const [content, setContent] = useState("");
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);




  function validateForm() {
    return content.length > 0;
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  async function handleSubmit(event) {
  event.preventDefault();

  const submissionDict = {notes: content, username: username}

  if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
    alert(
      `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
        1000000} MB.`
    );
    return;
  }

  setIsLoading(true);

  try {
    const attachment = file.current
      ? await s3Upload(file.current)
      : null;

    await createNote({ submissionDict, attachment });
    props.history.push("/dashboard");
  } catch (e) {
    alert(e);
    setIsLoading(false);
  }
}

function createNote(note) {
  return API.post("notes", "/notes", {
    body: note
  });
}
  return (
    <div className="general-background">
    <p>
      <input className='inputBoxes'
        onChange={handleFileChange}
        id="file"
        type="file"
      />
      <label for="file"> Select a file to upload</label>
  </p>
    <form onSubmit={handleSubmit}>
    <input className='inputBoxes'
      onChange={e => setContent(e.target.value)}
      value={content}
      id="content"
      type="name"
      placeholder="Link"/>


  {file.current ? file.current.name : null}
  <LoaderButton
    block
    type="submit"
    isLoading={isLoading}
    disabled={!validateForm()}
  >
    Create
  </LoaderButton>
    </form>

    </div>
  );
}
