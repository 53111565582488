import React from 'react';
import { Container, Row, Col} from 'react-grid-system';

class Footer extends React.Component {
  render() {
    return (
      <section className="footer">
      <Container>
      <Row>
      <Col lg={9}>
      Copyright © 2019 Deep Tournament, Inc. All rights reserved.
      </Col>
      <Col lg={3}>
    {/*Terms of Use & Privacy Policy*/}
      </Col>
      </Row>
      </Container>
      </section>
    )
  }
}


export default Footer;
