import React, { useState,useEffect  } from "react";
import { Auth } from "aws-amplify";

import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";


import { Link } from "react-router-dom";

import Modal from 'react-modal';
import { Canvas } from 'react-canvas-js';
import {
  Navbar, NavDropdown, Form, Button, FormGroup, FormControl, ControlLabel , Nav } from 'react-bootstrap';
import { Container, Col } from 'react-grid-system';
import Footer from './Footer.js';




export default function PublicLeaderboard(props) {









  return (






    <section>
    <div className="general-background">
    <p>
    <img class='logo' src="/images/logo.png"/>
    </p>
      <p>
      </p>
      <p>
      </p>

 <Container>

 <Col lg={12}>
 <div className="title">Global Leaderboard
 </div>
 <Link to="/" className="modalbutton">Back</Link>

 <a href="https://www.linkedin.com/in/erictranmsme/" className="modalbutton">1. Eric Tran</a>
 <a href="https://www.linkedin.com/in/priyaramasubbu/" className="modalbutton">2. Priya Ramasubbu</a>
 <a href="https://www.linkedin.com/in/vanessagomesvg/" className="modalbutton">3. Vanessa Gomes</a>
 <a href="https://www.linkedin.com/in//clsaito/" className="modalbutton">4. Cristiano Lopes Saito</a>
 <a href="https://www.linkedin.com/in/edilson-uiechi-77bb326/" className="modalbutton">5. Edilson Uiechi</a>
 <a href="https://www.linkedin.com/in/gautierbrunet/" className="modalbutton">6. Gautier Brunet</a>
 <a href="https://www.linkedin.com/in/fabiopomes/" className="modalbutton">7. Fabio Pomes</a>
 <a className="modalbutton">8. David Henry</a>
 <a href="https://www.linkedin.com/in/stephaniemaysyap/" className="modalbutton">9. Steph Yap</a>
 <a href="https://www.linkedin.com/in/no%C3%ABl-coenraad/" className="modalbutton">10. Noel Coenraad</a>



</Col>
</Container>


</div>
<Footer/>
      </section>
  );
}
