import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="general-background">
    Sorry, page not found!
    <Link to="/" className="modalbutton" >Back</Link>
    </div>
  );
}
