import React, { useRef, useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";

import { s3Upload } from "../libs/awsLib";

export default function UserInfo(props) {
  const file = useRef(null);
  const [goals, setGoals] = useState("");
  const [username, setUsername] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [personality, setPersonality] = useState("");
  const [iq, setIQ] = useState("");
  const [preferences, setPreferences] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [twitter, setTwitter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    function loadNote() {
      return API.get("userInfo", `/userCreation/${props.match.params.id}`);
    }

    async function onLoad() {
      try {
        const note = await loadNote();
        const { username, goals, firstname, lastname, personality, iq, preferences, twitter, linkedin } = note;
        setUsername(username);
        setGoals(goals);
        setFirstname(firstname);
        setLastname(lastname);
        setPersonality(personality);
        setIQ(iq);
        setPreferences(preferences);
        setLinkedin(linkedin);
        setTwitter(twitter);
        
        console.log(props.match.params.id, "THID")
      } catch (e) {
        alert(e);
      }
    }
    onLoad();
  }, [props.match.params.id]);

  function validateForm() {
  return username.length > 0;
}

function formatFilename(str) {
  return str.replace(/^\w+-/, "");
}



function saveNote(submissionDict) {
  /*"notes" is name of API. This is set in config.js*/
  console.log(submissionDict, "SUBMISSION")
  return API.put("userInfo", `/userCreation/${props.match.params.id}`, {
    body: submissionDict
  });
}

async function handleSubmit(event) {
  let attachment;

  event.preventDefault();

  const submissionDict = {goals: goals}
  setIsLoading(true);

  try {

console.log(submissionDict, "NEW")
    await saveNote({submissionDict}
    );
    props.history.push("/");
  } catch (e) {
    alert(e);
    console.log(e)
    setIsLoading(false);
  }
}

function deleteNote() {
  return API.del("notes", `/notes/${props.match.params.id}`);
}

async function handleDelete(event) {
  event.preventDefault();

  const confirmed = window.confirm(
    "Are you sure you want to delete this note?"
  );

  if (!confirmed) {
    return;
  }

  setIsDeleting(true);

  try {
    await deleteNote();
    props.history.push("/");
  } catch (e) {
    alert(e);
    setIsDeleting(false);
  }
}

return (
  <div className="general-background">
  <form onSubmit={handleSubmit}>
  <input className='inputBoxes'
    onChange={e => setGoals(e.target.value)}
    value={goals}
    id="goals"
    type="name"
    placeholder="Goals"/>

  <input className='inputBoxes'
    onChange={e => setUsername(e.target.value)}
    value={username}
    id="username"
    type="name"
    placeholder="Username"/>

  <input className='inputBoxes'
    onChange={e => setFirstname(e.target.value)}
    value={firstname}
    id="firstname"
    type="name"
    placeholder="First Name"/>

    <input className='inputBoxes'
      onChange={e => setLastname(e.target.value)}
      value={lastname}
      id="lastname"
      type="name"
      placeholder="Last Name"/>

    <input className='inputBoxes'
      onChange={e => setPersonality(e.target.value)}
      value={personality}
      id="personality"
      type="name"
      placeholder="Personality"/>

      <input className='inputBoxes'
        onChange={e => setIQ(e.target.value)}
        value={iq}
        id="iq"
        type="name"
        placeholder="IQ"/>

      <input className='inputBoxes'
        onChange={e => setPreferences(e.target.value)}
        value={preferences}
        id="preferences"
        type="name"
        placeholder="Preferences"/>

        <input className='inputBoxes'
          onChange={e => setLinkedin(e.target.value)}
          value={linkedin}
          id="linkedin"
          type="name"
          placeholder="Linkedin"/>

        <input className='inputBoxes'
          onChange={e => setTwitter(e.target.value)}
          value={twitter}
          id="twitter"
          type="name"
          placeholder="Twitter"/>

<LoaderButton
  block
  type="submit"
  isLoading={isLoading}
  disabled={!validateForm()}
>
  Create
</LoaderButton>
</form>

</div>
);
}
