import React, { useState,useEffect  } from "react";
import { Auth } from "aws-amplify";

import PropTypes from "prop-types";
import Modal from 'react-modal';
import Countdown from 'react-countdown-now';

import { Container, Col } from 'react-grid-system';
import Collapsible from 'react-collapsible';
import {Link} from 'react-router-dom'
import Notes from './Notes'
import UserInfo from './UserInfo'
import PublicLeaderboard from './PublicLeaderboard'


export default function EditUser(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
const [password, setPassword] = useState("");

const deadline = props.deadline;

const [isMobile, setIsMobile] = useState((window.innerWidth > 768 ? false : true));

  const [statisticsModalIsOpen, statisticsOpenModal] = useState(false);
const [statisticsModalIsOpen2, statisticsOpenModal2] = useState(false);

async function handleLogout() {
await Auth.signOut();

props.userHasAuthenticated(false);
props.history.push("/");
console.log(props.isAuthenticated)
}

useEffect(() => {
  console.log(isMobile)
    window.addEventListener('resize', () => {
        setIsMobile(window.innerWidth < 768)

    }, false);
}, []);





  function statisticsOpenModalPlease(){
    statisticsOpenModal(!statisticsModalIsOpen);
    console.log(statisticsModalIsOpen)
  }

  // Renderer callback with condition
function renderer({ days,hours, minutes, seconds, completed }){
    if (completed) {
      // Render a completed state
      //this.setState({hemmingwayModalIsOpen: true});
      return "Time's up!";
    } else {
      // Render a countdown
      return <span>{days}:{hours}:{minutes}:{seconds}</span>;
    }
  };







  function renderMobile(){
    return (
      <div className="mobilepage">
      <p>
      </p>
      <p>
      </p>


  <div>
  <Collapsible trigger={<img class='logo' src="/images/logo.png"/>}>
  <Container>

  <Col lg={12}>
  <span className='mobilecountdown'>
  <Countdown date={deadline}  renderer={renderer} />
  </span>
  <Link className="modalbutton" to="/mission">Mission</Link>
  <Link className="modalbutton" to="/leaderboard">Leaderboard</Link>
  <Link className="modalbutton" to="/tournaments">Tournaments</Link>
  <Link className="modalbutton" to="/settings">Settings</Link>

<button onClick={handleLogout} className="modalbutton">Leave Game</button>
  </Col>
  </Container>
  </Collapsible>
  <div className="mobiledivider">
  {UserInfo(props)}
  </div>
  </div>
  </div>
    )
  }

  function renderDesktop(){
    return (
      <div className="c-main">

        <div className="c-results">
        <p>
        </p>
        <img class='logo' src="/images/logo.png"/>
        <div className="pollotirNavbar">



        <span className='countdown'>
        <b><Countdown className="countdown" date={deadline} renderer={renderer} />
        </b></span>
        <Link className="modalbutton" to="/mission">Mission</Link>
        <Link className="modalbutton" to="/leaderboard">Leaderboard</Link>
        <Link className="modalbutton" to="/tournaments">Tournaments</Link>
        <Link className="modalbutton" to="/settings">Settings</Link>
        <button onClick={handleLogout} className="modalbutton">Leave Game</button>

          </div>

        </div>
        <div className="divider">
        </div>

        <div className="c-text">
      {UserInfo(props)}


        </div>

      </div>
    )
  }










      return (
        <div>
        {isMobile ? renderMobile() : renderDesktop()}
        </div>

  );
}
