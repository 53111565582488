import React from "react";
import { Link } from "react-router-dom";

export default function WaitingList() {
  return (
    <div className="general-background">
    <p>
    <img class='logo' src="/images/logo.png"/>
    </p>
      <p>
      </p>
      <p>
      </p>
    You're added to the waiting list!
    <div className='subtitle'>
    We've received lots of requests and we onboard as many as we can. We have to keep the tournament safe from bad actors, so the best way to expedite the process is by getting a referral from someone who is already a part of the Deep Tournament community.
    </div>

    <Link to="/" className="modalbutton" >Back</Link>
    </div>


  );
}
