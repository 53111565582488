import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import AppliedRoute from "./components/AppliedRoute";
import Signup from "./containers/Signup";
import Mission from "./containers/Mission";
import EditNote from "./containers/EditNote";
import EditUser from "./containers/EditUser";
import AuthenticatedRoute from "./components/AuthenticatedRoute";

import Dashboard from "./containers/Dashboard";
import Leaderboard from "./containers/Leaderboard";
import PublicLeaderboard from "./containers/PublicLeaderboard";
import Settings from "./containers/Settings";
import Tournaments from "./containers/Tournaments";
import PlayMenu from "./containers/PlayMenu";
import WaitingList from "./containers/WaitingList";
import About from "./containers/About";


import ShowNotesHome from "./containers/ShowNotesHome";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      <AppliedRoute path="/playmenu" exact component={PlayMenu} appProps={appProps} />
      <AppliedRoute path="/login" exact component={Login} appProps={appProps} />
      <AppliedRoute path="/waitinglist" exact component={WaitingList} appProps={appProps} />
      <AppliedRoute path="/publicleaderboard" exact component={PublicLeaderboard} appProps={appProps} />
      <AppliedRoute path="/about" exact component={About} appProps={appProps} />


<AppliedRoute path="/signup" exact component={Signup} appProps={appProps} />

<AuthenticatedRoute path="/dashboard" exact component={Dashboard} appProps={appProps} />
<AuthenticatedRoute path="/tournaments" exact component={Tournaments} appProps={appProps} />
<AuthenticatedRoute path="/settings" exact component={Settings} appProps={appProps} />
<AuthenticatedRoute path="/leaderboard" exact component={Leaderboard} appProps={appProps} />
<AuthenticatedRoute path="/mission/" exact component={Mission} appProps={appProps} />


<AuthenticatedRoute path="/notes/:id" exact component={EditNote} appProps={appProps} />
<AuthenticatedRoute path="/userCreation/:id" exact component={EditUser} appProps={appProps} />

      { /* Finally, catch all unmatched routes */ }
      <Route component={NotFound} />
    </Switch>
  );
}
