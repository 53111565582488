import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup, ListGroupItem,Button, ButtonToolbar } from "react-bootstrap";
/*import "./Home.css";*/
import { API } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Col } from 'react-grid-system';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css'
import { Canvas } from 'react-canvas-js';
import {Link} from 'react-router-dom'
import Footer from './Footer.js';


import { Auth } from "aws-amplify";


export default function Home(props) {
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true)


  async function handleLogout() {
  await Auth.signOut();

  props.userHasAuthenticated(false);
  props.history.push("/");
  console.log(props.isAuthenticated)
  }


  useEffect(() => {
  async function onLoad() {
    if (!props.isAuthenticated) {
      return;
    }
    props.history.push("/dashboard");

    try {

    } catch (e) {
      alert(e);
    }

    setIsLoading(false);
  }

  onLoad();
}, [props.isAuthenticated]);




function renderHome(){
  return(
    <div>
  <section>
  <p>
  </p>
  <p>
  </p>
  <Container>

  <Col lg={12}>
        <Col lg={12}>

        <div>

          <h1 className="title"><Typist cursor={{show:true, blink: true, element: '|'}}>
          Deep
          <span> Space</span>
          <Typist.Backspace count={6} delay={200} />
          <span> Ocean</span>
          <Typist.Backspace count={6} delay={200} />
          <span> Learning</span>
          <Typist.Backspace count={9} delay={200} />
          <span> Web</span>
          <Typist.Backspace count={4} delay={200} />
          <span> Tech</span>
          <Typist.Backspace count={5} delay={200} />
          <span> Thought</span>
          <Typist.Backspace count={8} delay={200} />
          <span> Tournament</span>
          </Typist></h1>

        </div>
        <div className="subtitle">Compete To Change The World
        </div>
        <p>
        <img class='logo' src="/images/logo.png"/>
        </p>
        </Col>
        <Link to="/playmenu" className="modalbutton">New Game</Link>
        <Link to="/publicleaderboard" className="modalbutton">Leaderboard</Link>
        <Link to="/about" className="modalbutton">Help</Link>
        <a href="https://www.google.com" className="modalbutton">Exit Game</a>

      </Col>
    </Container>
    </section>
    </div>
  )
}



function renderDashboard(){
  return (
    <div>
    <Link to="/login" className="modalbutton">Play</Link>
    <button onClick={handleLogout} className="modalbutton">LOGOUT</button>
    <Footer/>
    </div>
  )
}




    return (

<section>
        <div className = 'general-background'>
          {renderHome()}
        </div>
<Footer/>
      </section>
    );
  }
