import React, { useRef, useState, useEffect } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";

import { API } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";

export default function SettingsUpload(props) {
  const file = useRef(null);
  const [goals, setGoals] = useState("");
  const [username, setUsername] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [personality, setPersonality] = useState("");
  const [iq, setIQ] = useState("");
  const [preferences, setPreferences] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [twitter, setTwitter] = useState("");
  const [isLoading, setIsLoading] = useState(false);




  function validateForm() {
    return goals.length && firstname.length > 0;
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  async function handleSubmit(event) {
  event.preventDefault();

  try {
    const checkanswer = await checkUser();
    console.log(checkanswer,'dfahsjdflhsjafkh');
  } catch (e) {
    console.log(e, 'duh')
  }

  try {
    const checkanswer2 = await checkUserWithoutID();
    if (checkanswer2.length != 0){
      console.log(checkanswer2,'WIHOTU ID');
    } else {
      console.log('duh WIHOTU ID')
    }
  } catch (e) {
    console.log(e, 'duh WIHOTU ID')
  }

  const submissionDict = {goals: goals, username: username, firstname:firstname,
                          lastname: lastname, personality:personality, iq:iq, preferences:preferences,
                        linkedin:linkedin, twitter:twitter}
  console.log(submissionDict)

  setIsLoading(true);

  try {
    await createNote({ submissionDict });
    props.history.push("/dashboard");
  } catch (e) {
    alert(e);
    setIsLoading(false);
  }
}

function checkUser() {
  return API.get("userInfo", '/userCreation/kseow');
}

function checkUserWithoutID() {
  return API.get("userInfo", '/userCreation/check/kseow');
}

function createNote(submissionDict) {
  return API.post("userInfo", "/userCreation", {
    body: submissionDict
  });
}
  return (
    <div className="general-background">
    <form onSubmit={handleSubmit}>
    <input className='inputBoxes'
      onChange={e => setGoals(e.target.value)}
      value={goals}
      id="goals"
      type="name"
      placeholder="Goals"/>

    <input className='inputBoxes'
      onChange={e => setUsername(e.target.value)}
      value={username}
      id="username"
      type="name"
      placeholder="Username"/>

    <input className='inputBoxes'
      onChange={e => setFirstname(e.target.value)}
      value={firstname}
      id="firstname"
      type="name"
      placeholder="First Name"/>

      <input className='inputBoxes'
        onChange={e => setLastname(e.target.value)}
        value={lastname}
        id="lastname"
        type="name"
        placeholder="Last Name"/>

      <input className='inputBoxes'
        onChange={e => setPersonality(e.target.value)}
        value={personality}
        id="personality"
        type="name"
        placeholder="Personality"/>

        <input className='inputBoxes'
          onChange={e => setIQ(e.target.value)}
          value={iq}
          id="iq"
          type="name"
          placeholder="IQ"/>

        <input className='inputBoxes'
          onChange={e => setPreferences(e.target.value)}
          value={preferences}
          id="preferences"
          type="name"
          placeholder="Preferences"/>

          <input className='inputBoxes'
            onChange={e => setLinkedin(e.target.value)}
            value={linkedin}
            id="linkedin"
            type="name"
            placeholder="Linkedin"/>

          <input className='inputBoxes'
            onChange={e => setTwitter(e.target.value)}
            value={twitter}
            id="twitter"
            type="name"
            placeholder="Twitter"/>

  <LoaderButton
    block
    type="submit"
    isLoading={isLoading}
    disabled={!validateForm()}
  >
    Create
  </LoaderButton>
    </form>

    </div>
  );
}
