const dev = {
  /*STRIPE_KEY: "YOUR_STRIPE_DEV_PUBLIC_KEY",*/
  s3: {
    REGION: "us-east-1",
    BUCKET: "deep-tournament-app-backend-dev-attachmentsbucket-cypr4acq3iew"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://yj2hi8m2tk.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_Afbo32cCk",
    APP_CLIENT_ID: "176r667bf7uckf7vklu4mtsvi1",
    IDENTITY_POOL_ID: "us-east-1:2679a37e-651d-4f2c-a512-d447ede776af"
  }
};

const prod = {
  /*STRIPE_KEY: "YOUR_STRIPE_PROD_PUBLIC_KEY",*/
  s3: {
    REGION: "us-east-1",
    BUCKET: "deep-tournament-app-backend-pro-attachmentsbucket-1x776hylk1mem"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://n95ti8b0t6.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_yXOBYPlRq",
    APP_CLIENT_ID: "7e25qd92j66vhs8l258jptd0nh",
    IDENTITY_POOL_ID: "us-east-1:1f324f57-ba0b-46ec-9bc9-8e8455041657"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
