import React, { useState,useEffect  } from "react";
import { Auth } from "aws-amplify";

import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";


import { Link } from "react-router-dom";

import Modal from 'react-modal';
import { Canvas } from 'react-canvas-js';
import {
  Navbar, NavDropdown, Form, Button, FormGroup, FormControl, ControlLabel , Nav } from 'react-bootstrap';
import { Container, Col } from 'react-grid-system';
import Footer from './Footer.js';




export default function PlayMenu(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
const [password, setPassword] = useState("");

  const [statisticsModalIsOpen, statisticsOpenModal] = useState(false);
const [statisticsModalIsOpen2, statisticsOpenModal2] = useState(false);



useEffect(() => {
  if (props.isAuthenticated) {
      props.history.push("/dashboard");
    }
}, []);




function onChangeEmail(e) {
    setEmail(e.target.value);
  };

  function onChangePassword(e) {
      setPassword(e.target.value);
    };

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function statisticsOpenModalPlease(){
    statisticsOpenModal(!statisticsModalIsOpen);
    console.log(statisticsModalIsOpen)
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(email, password);
      props.userHasAuthenticated(true);
      props.history.push("/dashboard");

    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  return (






    <section>
    <div className="general-background">
    <p>
    <img class='logo' src="/images/logo.png"/>
    </p>
      <p>
      </p>
      <p>
      </p>

 <Container>

 <Col lg={12}>
 <Link to="/login" className="modalbutton">Choose Your Character</Link>
  <Link to="/signup" className="modalbutton" >Request Access</Link>
 <Link to="/" className="modalbutton">Main Menu</Link>


</Col>
</Container>


</div>
<Footer/>
      </section>
  );
}
