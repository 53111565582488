import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup, ListGroupItem,Button, ButtonToolbar } from "react-bootstrap";
/*import "./Home.css";*/
import { API } from "aws-amplify";

import { Container, Col } from 'react-grid-system';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css'
import { Canvas } from 'react-canvas-js';
import {Link} from 'react-router-dom'
import Footer from './Footer.js';
import { LinkContainer } from "react-router-bootstrap";


import { Auth } from "aws-amplify";


export default function ShowUserInfoFromDatabase(props) {
  const [userInfo, setUserInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true)


  async function handleLogout() {
  await Auth.signOut();

  props.userHasAuthenticated(false);
  props.history.push("/");
  console.log(props.isAuthenticated)
  }


  useEffect(() => {
  async function onLoad() {
    if (!props.isAuthenticated) {
      return;
    }
    /*props.history.push("/dashboard");*/

    try {
      const userInfo = await loadUserInfo();
              setUserInfo(userInfo);
              console.log(userInfo)
    } catch (e) {
      alert(e);
    }

    setIsLoading(false);
  }

  onLoad();
}, [props.isAuthenticated]);





function loadUserInfo() {
    return API.get("userInfo", "/userCreation");
  }


function renderNotesList(userInfo) {
   return [{}].concat(userInfo).map((note, i) =>
     i !== 0 ? (
       <div>
       <LinkContainer key={note.username} to={`/userCreation/${note.username}`}>
         <ListGroupItem header={note.twitter}>
           {"Created: " + new Date(note.createdAt).toLocaleString()}
         </ListGroupItem>
       </LinkContainer>
       </div>
     ) : (
      null
     )
   );
 }

function renderNotes() {
    return (
      <div className="notes">
        <ListGroup>
          {!isLoading && renderNotesList(userInfo)}
        </ListGroup>
      </div>
    );
  }





    return (

<section>
        <div className = 'general-background'>
          {renderNotes()}
        </div>
<Footer/>
      </section>
    );
  }
