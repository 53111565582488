import React, { useState,useEffect  } from "react";
import { Auth } from "aws-amplify";

import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";


import { Link } from "react-router-dom";

import Modal from 'react-modal';
import { Canvas } from 'react-canvas-js';
import {
  Navbar, NavDropdown, Form, Button, FormGroup, FormControl, ControlLabel , Nav } from 'react-bootstrap';
import { Container, Col } from 'react-grid-system';
import Footer from './Footer.js';




export default function About(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
const [password, setPassword] = useState("");









  return (






    <section>
    <div className="general-background">
    <p>
    <img class='logo' src="/images/logo.png"/>
    </p>
      <p>
      </p>
      <p>
      </p>

 <Container>

 <Col lg={12}>
 <div className="title">Help
 </div>

  <Link to="/" className="modalbutton">Back</Link>

 <div>What is Deep Tournament?
 <div className="subtitle">Deep Tournament is a competition platform that connects individual aspiring entrepreneurs with one another to compete to build a company/solution around IP. The IP itself doesn't really matter. The main purpose is for our individual competitors to <i>connect</i> with one another and <i>network</i> through working together. The IP is just a common goal for you and your teammates to share to connect. If you happen to start a company along the way, that's just icing on the cake.
</div>
 </div>


<div>Why Deep Tournament?
<div className="subtitle">
We want to enable entrepreneurs globally. We truly believe talent is <i>everywhere</i>, not just on the east and west coast. Networking, finding co-founders and an idea are some of the biggest challenges to starting a company. We believe we can help talent connect and work together through this tournament.
</div></div>


<div>
Why not just go to meetups or other networking events?
<div className="subtitle">
You can and you should. But there's a lot of awkwardness and friction at times. Networking doesn't come natural for most. Sam Altman said that the best place to network and find your co-founders is to actually work with them, i.e., college or top companies. Those are limited places. We want to build that same environment online. We believe that putting you in teams with 3-4 other people removes the awkwardness and allows you to <i>actually</i> work together on a common goal to see if you can work together in real life.
</div></div>

<div>
How much does Deep Tournament cost?
<div className="subtitle">
$10 per month.</div></div>


<div>
How do you decide teams?
<div className="subtitle">
Once you get off the waiting list, you take personality and cognitive tests, and fill out a questionnaire on your background and preferences. We feed this data into our algorithms to form teams of 3-4 people.
</div></div>

<div>
So how does the tournament work?
<div className="subtitle">
Competitions range from 2 days to 8 weeks. Teams get placed in a private slack channel and from there, it's on you all to set up Zoom calls or meet in person to begin building a company around the IP. Each team will submit a pitch deck every N days, with a voiceover describing their company. We crowdsource voting from the rest of the Deep Tournament community outside of the competition. At the end of the competition, the top 3-5 teams will do a live stream with the owners of the IP.
</div></div>

<div>
I've been on the wait list for while. How do I get approved?
<div className="subtitle">
We've received lots of requests and we onboard as many as we can. We have to keep the tournament safe from bad actors, so the best way to expedite the process is by getting a referral from someone who is already a part of the Deep Tournament community.</div></div>

</Col>
</Container>


</div>
<Footer/>
      </section>
  );
}
